<template>
    <div class="user-info" v-if="user">

        <div class="user-info-title">Пользователь #{{ user.id }}</div>

        <div class="user-info-buttons-group" >
            <a :href="'https://talap.wigital.ru/bitrix/admin/user_edit.php?lang=ru&ID=' +  user.id" target="_blank" class="btn"><span>Профиль</span></a>
            <a :href="'https://talap.wigital.ru/bitrix/admin/sale_account_edit.php?ID=' +  user.walletId" target="_blank" class="btn"><span>Кошелек</span></a>
            <a :href="'https://talap.wigital.ru/bitrix/admin/sale_order.php?PAGEN_1=1&SIZEN_1=20&lang=ru&set_filter=Y&adm_filter_applied=0&filter_universal='+user.login+'&filter_date_from_FILTER_DIRECTION=previous&filter_date_update_from_FILTER_PERIOD=interval&filter_date_update_from_FILTER_DIRECTION=previous' " target="_blank" class="btn"><span>Заказы</span></a>
        </div>

        <div class="user-info-rows">
            <div class="user-info-row">
                <div class="user-info-row-title">ID</div>
                <div class="user-info-row-value">{{ user.id }}</div>
            </div>

            <div class="user-info-row">
                <div class="user-info-row-title">Логин</div>
                <div class="user-info-row-value">{{ user.login }}</div>
            </div>

            <div class="user-info-row">
                <div class="user-info-row-title">Имя</div>
                <div class="user-info-row-value">{{ user.name }}</div>
            </div>

            <div class="user-info-row">
                <div class="user-info-row-title">Фамилия</div>
                <div class="user-info-row-value">{{ user.lastName }}</div>
            </div>

            <div class="user-info-row">
                <div class="user-info-row-title">Email</div>
                <div class="user-info-row-value">{{ user.email }}</div>
            </div>

            <div class="user-info-row">
                <div class="user-info-row-title">Статус</div>
                <div class="user-info-row-value">{{ user.status && user.status.value ? user.status.value : '' }}</div>
            </div>

    
            <div class="user-info-row">
                <div class="user-info-row-title">Язык в приложении</div>
                <div class="user-info-row-value">{{ user.locale }}</div>
            </div>

            <div class="user-info-row" >
                <div class="user-info-row-title">Бонусы</div>
                <div class="user-info-row-value"> {{ user.walletValue ? user.walletValue : 0 }} </div>
            </div>

            <div class="user-info-row" v-if="user.walletUpdated">
                <div class="user-info-row-title">Бонусы обновлены</div>
                <div class="user-info-row-value"> {{ user.walletUpdated }}  </div>
            </div>

        </div>

        <div class="user-devices">
            <div class="user-devices-title">Устроства пользователя</div>
            <table>
                <tr>
                    <th>Название</th>
                    <th>Город</th>
                    <th>Версия</th>
                </tr>

                <tr v-for="device in user.devices">
                    <td>{{ device.name }}</td>
                    <td>{{ device.city }}</td>
                    <td>{{ device.client }}</td>
                </tr>

            </table>
        </div>

       



        <div style="margin-top: 2rem;">
            <div>Выполнить</div>

            <div class="user-info-buttons">
                <select v-model="state.action"> <option v-for="action in actions" :value="action.action">{{ action.name }}</option> </select>
                <button type="button" class="btn user-info-btn" :class="{'--is-loading':state.isActionLoading}" :disabled="!action || state.isActionLoading" @click="() => startAction()"><span>Выполнить</span></button>
            </div>
        </div>

        <div v-if="actionResult">
            <ActionsResult :data="actionResult" :action="action"></ActionsResult>
        </div>

    </div>
</template>
<script>

    import ActionsResult from './ActionsResult.vue';

    export default{
        props:['userId'],
        components:{ActionsResult},
        data(){
            return {
                user:null,
                actionResult:null,
                actions:[
                    {action:'renewAnalises', name:'Обновить анализы'},
                    {action:'renewAppointments', name:'Обновить назначения'},
                    {action:'renewDiagnostics', name:'Обновить исследования'},
                    {action:'renewProfile', name:'Обновить статус, бонусы и пользователя'},
                ],
                state:{
                    isActionLoading:false,
                    action:null,
                }
            }
        },
        watch:{
            'state.action'(){
                this.actionResult = null;
            }
        },
        computed:{
            action(){
                if(!this.state.action) return;
                return this.actions.find(a => a.action == this.state.action)
            }
        },  
        async mounted(){
            console.log('User info')
            let params = new URLSearchParams();
            params.set('userId', this.userId)
            this.user = await this.$useFetch('/support/manager/dialog/user?' + params.toString())  
        },
        methods:{
            async updateProfile(){
                const result = await this.$useFetch('/support/manager/dialog/close', {
                    method:'POST',
                    body: JSON.stringify({
                        dialogId:this.dialog.id,
                    })
                })
            },

            async startAction(){

                if(!this.action){
                    alert('Выберите действие')
                    return false;
                }

                let routes = {
                    'renewProfile': `/profile/renew/${this.user.login}`,
                    'renewAnalises': `/appointment-result/callFromServer/${this.user.login}/Analises`,
                    'renewDiagnostics': `/appointment-result/callFromServer/${this.user.login}/DiagIssledovaniya`,
                    'renewAppointments': `/appointment-result/callFromServer/${this.user.login}/Naznacheniya`,
                }

                this.state.isActionLoading = true;
                this.actionResult = await this.$useFetch(routes[this.action.action], { nudeResult:true })
                this.state.isActionLoading = false;

            }
            
        }
    }
</script>
<style lang="scss">

.user-devices{
    margin-top: 1rem;
    &-title{
        display: block;
        margin-bottom: 10px;
        font-weight: 500;
    }
    table{
        width: 100%;
        border-collapse: collapse;
    }

    th{
        text-align: left;
        font-weight: 500;
    }

    table, td, th{
        font-size: 14px;
    }
    td, th{
        padding: 5px;
    }
    tr:nth-child(even) td{
        background: #efefef;
    }
}

.user-info{
    display: block;
    min-width: 400px;
}

.user-info-title{
    font-weight: 500;
    display: block;
    margin-bottom: 1rem;
    font-size: 20px;
}

.user-info-buttons-group{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 0rem;
    margin-bottom: 1rem;

    font-size: 14px;
    a{
        color: #000;
    }
}

.user-info-buttons{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 1rem;

    select, button{
        border: 1px solid #ddd;
        border-radius: 4px;
        padding: 5px 10px;
        
        height: 34px;
        cursor: pointer;
    }

    button{
        background: #ead2ff;
        padding: 5px 10px;
        
        border: none;
        box-shadow: inset 0px 0px 0px 1px #0000001c;
        
    }
}

.user-info-rows{
    width: 100%;
    display: table;
    line-height: 1;
    font-size: 14px;
    .user-info-row{
        display: table-row;
        .user-info-row-title{
            padding: 5px;
            font-weight: 500;
            display: table-cell;
        }
        .user-info-row-value{
            display: table-cell;
            padding: 5px;
        }
        &:nth-child(even){
            background-color: #efefef;
        }
    }
}

</style>